define("iris/components/kanban/custom-cards/resource-plan-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcePlanKanbanCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.computed('card.currentPlanId', function () {
    if (this.card.currentPlanId) {
      return this.store.peekRecord('resourcePlan', this.card.currentPlanId);
    }
  }), _dec9 = Ember.computed('card.ticketIsOperational', 'card.availableSpentTimes', function () {
    if (!this.card.ticketIsOperational) {
      return this.card.availableSpentTimes > 0 ? this.card.availableSpentTimes : 0;
    }
  }), _dec10 = Ember.computed('currentPlan', 'model.priorities', function () {
    if (!this.priorities || this.priorities.length < 1) {
      return;
    }

    return this.priorities.find(priority => priority.id === this.currentPlan.get('priority.id'));
  }), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ResourcePlanKanbanCard extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "modals", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionAccount", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor6, this);

      _initializerDefineProperty(this, "loading", _descriptor7, this);

      _initializerDefineProperty(this, "currentPlan", _descriptor8, this);

      _initializerDefineProperty(this, "maxAssignableHours", _descriptor9, this);

      _initializerDefineProperty(this, "currentPlanPriority", _descriptor10, this);
    }

    togglePopover() {
      this.card.toggleProperty('popover');
      this.togglePopover(this.card);
    }

    async openTicket(ticketIsOperational, ticketId) {
      let router = Ember.getOwner(this).lookup('service:router');
      let url = ticketIsOperational ? router.urlFor('operation.sd-dashboard.edit', ticketId) : router.urlFor('tasks.tasks.work-logs', ticketId);
      window.open(url);
    }

    async addPlan(card) {
      this.loading.run(async () => {
        let naPrio = await this.store.findRecord('resourcePlanPriority', 'SYS_01');
        let newPlan = await this.store.createRecord('resourcePlan', {
          plannedHours: 0,
          tuaId: card.id,
          priority: naPrio,
          resourcePeriod: this.resourcePeriod
        });
        await newPlan.save();
      });
    }

    async removePlan(card) {
      this.loading.run(async () => {
        try {
          await this.currentPlan.destroyRecord(true);
          this.flashMessages.success(this.intl.t('resource-plan.save-success'), {
            title: this.intl.t('resource-plan.save-title'),
            icon: 'check'
          });
        } catch (error) {
          this.flashMessages.danger({
            title: `Error Saving`,
            icon: 'warning',
            sticky: true
          });
        }

        this.refresh();
      });
    }

    async changePlanPrio(card, prio) {
      await this.currentPlan.set('priority', prio);
      await this.savePlan();
      this.set('priorityPopover', false);
    }

    async changePlanPlannedHour() {
      Ember.run.debounce(this, this.savePlan, {}, 300);
    }

    async savePlan() {
      this.loading.run(async () => {
        try {
          await this.currentPlan.save();
          this.flashMessages.success(this.intl.t('resource-plan.save-success'), {
            title: this.intl.t('resource-plan.save-title'),
            icon: 'check'
          });
        } catch (error) {
          this.flashMessages.danger({
            title: `Error Saving`,
            icon: 'warning',
            sticky: true
          });
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modals", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentPlan", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "maxAssignableHours", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currentPlanPriority", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "togglePopover", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "togglePopover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openTicket", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openTicket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPlan", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addPlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePlan", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "removePlan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePlanPrio", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "changePlanPrio"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePlanPlannedHour", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "changePlanPlannedHour"), _class.prototype)), _class));
  _exports.default = ResourcePlanKanbanCard;
});