define("iris/components/kanban/column/component", ["exports", "@salsify/ember-block-slots"], function (_exports, _emberBlockSlots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let KanbanColumn = (_dec = Ember.computed('column.cards.@each', 'filteredCards', 'selectedSortProperty'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class KanbanColumn extends Ember.Component.extend(_emberBlockSlots.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "filteredCards", Ember.A());

      _defineProperty(this, "searchOpened", false);

      _defineProperty(this, "sortOpened", false);

      _defineProperty(this, "selectedSortProperty", null);

      _defineProperty(this, "scrolled", false);
    }

    init() {
      super.init(...arguments);
      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        this.searchOpened = true;
        this.column.search = foundSearchElement.searchTerm;
        this.search();
      }

      let scrollElement = document.getElementById(`scroll-back-container-${this.column.id}`);
      scrollElement.addEventListener("scroll", event => {
        this.set('scrolled', scrollElement.scrollTop === 0 ? false : true);
      });
    }

    get displayCards() {
      if (this.searchOpened && this.column.search) {
        return this.filteredCards;
      }

      return this.column.cards;
    }

    async openSearch() {
      this.set('searchOpened', true);
    }

    async openSort() {
      this.set('sortOpened', true);
    }

    async closeSearchSort() {
      this.set('filteredCards', Ember.A());
      this.set('selectedSortProperty', null);
      this.set('searchOpened', false);
      this.set('sortOpened', false);
      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        this.searchList.removeObject(foundSearchElement);
      }
    }

    async searchAction() {
      Ember.run.debounce(this, this.search, {}, 300);
    }

    async search() {
      const splittedProps = this.searchProperties.map(prop => prop.split('.'));
      let filteredCards = [];

      for (const splittedProp of splittedProps) {
        let tempfilteredCards = this.column.cards.filter(card => {
          let temp = card;
          splittedProp.forEach(prop => {
            temp = temp[prop];
          });
          return temp && temp.toLowerCase().includes(this.column.search.toLowerCase());
        });
        filteredCards = [...filteredCards, ...tempfilteredCards];
      }

      this.set('filteredCards', filteredCards);
      let foundSearchElement = this.searchList.find(element => element.columnId === this.column.id);

      if (foundSearchElement) {
        foundSearchElement.searchTerm = this.column.search;
      } else {
        this.searchList.addObject({
          columnId: this.column.id,
          searchTerm: this.column.search
        });
      }
    }

    async dragEndAction(args) {
      let {
        sourceList,
        sourceIndex,
        targetList,
        targetIndex,
        draggedItem
      } = args;
      if (sourceList === targetList && sourceIndex === targetIndex) return;
      const item = sourceList.objectAt(sourceIndex);
      await sourceList.removeAt(sourceIndex);
      await targetList.insertAt(targetIndex, item);

      if (this.saveAction) {
        await this.saveAction(args);
      }

      if (this.sortOpened) {
        this.set('filteredCards', Ember.A());
        this.set('sortOpened', false);
        this.set('selectedSortProperty', null);
      }
    }

    async scrollToTop() {
      $(`#scroll-back-container-${this.column.id}`).animate({
        scrollTop: 0
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "displayCards", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "displayCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSearch", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "openSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSort", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSearchSort", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeSearchSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "searchAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEndAction", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "dragEndAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToTop", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToTop"), _class.prototype)), _class));
  _exports.default = KanbanColumn;
});